import { useState } from 'react';
import { AppModalSearchDir } from './AppModalSearchDir.js'

export default function AppModalSearch(props) {

  console.log(props)


  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [searchString, setSearchString] = useState("");
  const [searchDir, setSearchDir] = useState(0);


  const fnHandler = props.fnHandler
  const itemsTree = props.itemsTree

  function handleSubmit(e) {
    e.preventDefault();
    //console.log(e)
    setError("")
    setLoading(true)
    fnHandler(searchDir, searchString)

    setTimeout(function () {
      setLoading(false)
    }, 1000);

  }
  return (

    <>

      <form onSubmit={handleSubmit} >

        <table>
          <tbody>
            <tr>
              <td>what:</td>
              <td> <input type="text"
                name="searchString" value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
              />
              </td>

            </tr>
            <tr>
              <td>where:</td>
              <td><select name="dirs" id="idDirsSearch"
                value={searchDir} onChange={(e) => (setSearchDir(e.target.value))}>
                <option value="0">/</option>
                {itemsTree.map((item) => (
                  <AppModalSearchDir item={item} key={item.id} />
                ))}

              </select></td>

            </tr>
          </tbody>
        </table>



        <div className='centerContent'>
          <button type="submit" >Submit</button>
          {loading && <div className='loading'>Loading...</div>}
        </div>
        {error && (<div className="borderRed"  >{error}</div>)}
      </form>

    </>
  );
}