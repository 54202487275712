import {useState} from 'react'
import "./AppBodyTreeItem.css"

export default function AppBodyTreeItem(props) {


  const item = props.item
  const tab = props.tab
  const fnHandler = props.fnHandler
  const clickedDir = props.clickedDir;

  return (
    <li style={{listStyle: 'none'}}>
      <a  onClick={() => fnHandler(item.id)}
      style={{ fontWeight: clickedDir === item.id ? 'bold' : 'normal' }}
      >{item.title}</a>
      <ul style={{listStyle: 'none'}}>
        {item.nodes.map((child) => (
          <AppBodyTreeItem item={child} key={child.id} tab={tab + "..."} 
          fnHandler={fnHandler}
          clickedDir={clickedDir}
          />
        ))}
      </ul>
    </li>
  );
}