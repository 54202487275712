import AppBodyTreeItem from './AppBodyTreeItem.js'
import { useEffect, useState } from 'react';
import { AppLoading } from './loading/AppLoading.js';

export default function AppBodyTree(props) {

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);
  const clickedDir = props.clickedDir;

  useEffect(() => {
    setData(props.items)
  }, [props.items])

  const tab = ""

  return (
    <div className="containerz">

      {props.isLoading && <AppLoading />}

      <ul style={{ border: '0px solid red', padding: '0', margin: '0', listStyle: 'none' }}>
        {data && data.map((item) => (

          <AppBodyTreeItem item={item} key={item.id} tab={tab + "..."}
            fnHandler={props.fnHandler} clickedDir={clickedDir} />

        ))}

      </ul>
    </div>
  );
}