import { useState, useEffect } from 'react';
import { AppModalSearchDir } from './AppModalSearchDir.js'
import { authTokenGet } from './AuthProfile';
import { itemGet } from './App.js';
import { AppLoading } from './loading/AppLoading.js';


const urlApi = "https://jdx2x68w02.execute-api.eu-central-1.amazonaws.com/api/nodes"

export default function AppModalAdd({ itemsTree, id, handleClose }) {

  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');

  const [item, setItem] = useState({})

  async function fetchItemData(id) {
    //console.log(id)
    setLoading(true)
    setItem({ 'id': null, 'title': '', 'descr': '' })
    if (id > 0)
      try {
        const data = await itemGet(id);
        console.log('Fetched Data:', data);
        setItem(data)
        console.log('item:', item);

      } catch (error) {
        console.error('Error fetching item data:', error);
      }
    setLoading(false)


  }

  useEffect(() => {
    fetchItemData(id)
  }, [id])


  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  function handleSubmit(e) {
    e.preventDefault();
    //(window.event).preventDefault();
    setLoading(true)

    console.log(e.target.elements.title.value);
    // console.log(e.title.value);

    // var form_data = new FormData(document.getElementById("idFormAdd"));
    var form_data = new FormData();
    form_data.append('parentId', e.target.elements.parentId.value)
    form_data.append('descr', e.target.elements.descr.value)
    form_data.append('title', e.target.elements.title.value)
    form_data.append('role', e.target.elements.role.value)
    form_data.append('attach', file)
    form_data.append('jwt', authTokenGet())
    form_data.append("id", item.id)

    console.log(form_data)
    //return;

    var xhr = new XMLHttpRequest();
    xhr.open("POST", urlApi, true);
    // xhr.setRequestHeader('Content-type', 'text/html');
    // xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    // xhr.setRequestHeader('Content-type', 'application/json');
    xhr.setRequestHeader('Authorization', 'Bearer ' + authTokenGet());

    xhr.send(form_data);

    xhr.onreadystatechange = function (e) {
      console.log("readyState:" + xhr.readyState);
      console.log("status:" + xhr.status);
      console.log("responseText:" + xhr.responseText);

      if (xhr.status === 200 && xhr.response.length > 0) {
        console.log("200 ok")
        let respItem = JSON.parse(xhr.response)
        console.log(respItem)
        let action = item?.id > 0 ? "UPD" : "ADD";
        let resp = { "action": action, "id": respItem.id }
        handleClose(resp)
      } else if (xhr.status >= 400) {
        console.log("err")
        setLoading(false)
      }
    }


  }

  let checkedDir = item?.role === 1 ? "checked" : null
  let checkedDoc = item?.role === 2 ? "checked" : null

  return (

    <>
      <form onSubmit={handleSubmit} id="idFormAdd" encType="multipart/form-data" method="post">
        <table>

          <tbody><tr>
            <td >Parent:</td>
            <td>
              <select name="parentId" id="idDirsSearch" >
                <option value="0">/</option>
                {itemsTree.map((itemT) => (
                  <AppModalSearchDir itemT={itemT} key={itemT.id} idSelected={item?.parentId} />
                ))}
              </select>
            </td>
          </tr>

            <tr>
              <td >Title:</td>
              <td><input type="text" defaultValue={item?.title} name="title"
              /></td>
            </tr>

            <tr>
              <td >Description:   </td>
              <td><textarea name="descr" defaultValue={item?.descr} >{item?.descr}</textarea></td>
            </tr>

            <tr>
              <td >Role:{item?.role}</td>
              <td>

                <input id='idDir' name="role" type="radio" value="1"
                  checked={item?.role === 1 ? "checked" : null} />
                <label htmlFor='idDir'><img src="img/folder-fill.svg" alt='Dir' />Dir</label>

                <br />

                <input id='idDoc' name="role" type="radio" value="2"
                  checked={item?.role === 2 ? "checked" : null} />
                <label htmlFor='idDoc'><img src="img/file.svg" alt='File' />doc</label>

              </td>
            </tr>

            <tr>
              <td >Image:</td>
              <td>
                <div className='containerz'>
                  <img src={'https://encaps.click/attach/' + item?.fileName} alt='img'
                  /></div>
                <div><input type="file" name="attach" onChange={handleFileChange} /></div>


              </td>
            </tr>
          </tbody></table>


        <div className='centerContent'>
          <button type="submit" >Submit</button>
          {loading && <AppLoading />}
        </div>
        {error && (<div className="borderRed"  >{error}</div>)}
        <input type='hidden' name="jwt" value={authTokenGet()} />
      </form>
    </>
  );
}