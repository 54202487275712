import './App.css';
import './colors.css';

import Head from './Head.js'
import AppBodyTree from './AppBodyTree.js'
import AppBodyList from './AppBodyList.js'
import Foot from './Foot.js'
import { useEffect, useState } from 'react';

export default function App() {

  const [items, setItems] = useState([])
  const [isLoadingItems, setIsLoadingItems] = useState(true)

  useEffect(() => {
    setIsLoadingItems(false)
  }, [items])

  // function fnItemsAdd(item) {
  //   setItems((items) => [...items, item])
  // }

  function fnScroll() {
    const isPortrait = window.matchMedia("(orientation: portrait)").matches;
    const isLandscape = window.matchMedia("(orientation: landscape)").matches;
    if (isPortrait) {
      console.log("Screen isPortrait");
      document.getElementById("divIdDocs").scrollIntoView();
    } else if (isLandscape) {
      console.log("Screen isLandscape");
    } else {
      console.log("Screen orientation not detected");
    }
  }

  const [clickedDir, setClickedDir] = useState(null);

  async function fnItemsGet(dir_, search_) {
    fnScroll();
    setIsLoadingItems(true)
    let dir = dir_ ? dir_ : 0;
    let search = search_ ? search_ : "";
    let url = "https://jdx2x68w02.execute-api.eu-central-1.amazonaws.com/api/nodes"
    url += `?dir=${dir}&search=${search}`
    const response = await fetch(url);
    const data = await response.json();
    // console.log(data);
    setItems(data);
    setClickedDir(dir);
  }

  /////////////////////////////////////////////

  const [itemsTree, setItemsTree] = useState([])
  const [isLoadingItemsTree, setIsLoadingItemsTree] = useState(true)

  async function fnItemsTreeGet() {
    setIsLoadingItemsTree(true)
    let url = "https://jdx2x68w02.execute-api.eu-central-1.amazonaws.com/api/nodes"
    const response = await fetch(url);
    const data = await response.json();
    console.log(data);
    if (Array.isArray(data))
      setItemsTree(data);
  }
  useEffect(() => {
    setIsLoadingItemsTree(false)
  }, [itemsTree])

  useEffect(() => {
    fnItemsTreeGet();
    fnItemsGet();
  }, [])

  return (
    <div className="App">
      <Head fnHandler={fnItemsGet} itemsTree={itemsTree} />

      <div className="container">
        <div className="containerLeft">
          <AppBodyTree items={itemsTree} fnHandler={fnItemsGet}
            isLoading={isLoadingItemsTree}
            clickedDir={clickedDir} />
        </div>
        <div className="containerRight">
          <AppBodyList items={items} isLoading={isLoadingItems}
            fnHandler={fnItemsGet} itemsTree={itemsTree} />
        </div>
      </div>

      <Foot />
    </div>
  );


}

export async function itemGet(id) {
  var url = "https://jdx2x68w02.execute-api.eu-central-1.amazonaws.com/api/nodes"
  url += "?id=" + id
  if (!id)
    return
  const response = await fetch(url);
  const data = await response.json();
  console.log(data);
  // if (Array.isArray(data))
  //   alert(data)
  return data;
}