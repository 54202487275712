export function AppModalSearchDir({ itemT, tab_, idSelected }) {
    //console.log(tab_)
    var tab = tab_ ? tab_ : '\u00A0';
    //console.log(tab);

    return (<>

        <option value={itemT.id} selected={itemT.id === idSelected}>{tab}{itemT.title}</option>

        {itemT.nodes?.map((node) => (
            <AppModalSearchDir itemT={node} key={node.id} tab_={tab + '\u00A0\u00A0'} idSelected={idSelected} />
        ))}

    </>);
}