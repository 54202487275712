import { useState, useEffect, useRef } from 'react';
import './AppBodyList.css';
import { AppLoading } from './loading/AppLoading.js';
import { authTokenGet } from './AuthProfile.js';

import ModalDialog from './ModalDialog.js';
import AppModalAdd from './AppModalAdd.js';
import ConfirmationDialog from './ConfirmationDialog'
import { itemGet } from './App.js';

export default function AppBodyList(props) {

  const [data, setData] = useState([])
  const fnHandler = props.fnHandler;
  const [loading, setLoading] = useState(false)
  const itemsTree = props.itemsTree;

  const [item, setItem] = useState({})


  useEffect(() => {
    setData(props.items)
  }, [props.items])


  async function onClickDeleteConfirm(id) {

    setLoading(true)

    var url = "https://jdx2x68w02.execute-api.eu-central-1.amazonaws.com/api/nodes"
    url += "?id=" + id + "&jwt=" + authTokenGet()
    const res = await fetch(url, {
      method: 'DELETE',
      // headers: {
      // 'Content-Type': 'application/json',
      //   "Access-Control-Allow-Origin": "*"
      // },
      // body: JSON.stringify(data)
    });
    console.log(res)
    setLoading(false)

    if (!res.ok) {
      throw new Error(`Error: ${res.statusText}`);
    } else {
      setData(data => data.filter(item => item.id !== id))
    }

  }

  const [edit, setEdit] = useState(false)
  const refModal = useRef()

  function onClickEdit(id_) {
    setEdit(old => !old)
    setId(id => id_)
    refModal.current.showModal()
  }

  async function fetchItemData(params) {
    try {
      const dataGet = await itemGet(params.id);
      console.log('Fetched:', dataGet);
      setItem(old => dataGet)
      console.log('item:', item);
      switch (params.action) {
        case "ADD": setData([...data, item]); break;
        case "UPD": setData(
          data.map(itemOld => itemOld.id === id ? dataGet : itemOld))
          break;
      }

    } catch (error) {
      console.error('Error fetching item data:', error);
    }
  }

  function handleClose(params) {
    refModal.current.close()
    console.log(params)
    fetchItemData(params)
  }

  function onBtnDeleteClick(id) {
    setDialogConfirm(true)
    setId(id)
  }

  const [dialogConfirm, setDialogConfirm] = useState(false)
  function handleDialogConfirm() {
    setDialogConfirm(false)
    onClickDeleteConfirm(id)
  }
  function handleDialogCancel() {
    setDialogConfirm(false)
  }

  const [id, setId] = useState(null)

  return (<>
    {authTokenGet() &&
      <button className=''
        onClick={() => onClickEdit(0)}>+Add</button>
    }

    <div id="divIdDocs" className="containerGrid">
      {props.isLoading && <AppLoading />}
      {!props.isLoading && data.length === 0 && <div className='centerContent'>Nothing found</div>}

      {false && data.filter((i) => i.role === 1).map((item) => (
        <span><img src="img/folder-fill.svg" alt='Dir' />{item.title}
          {authTokenGet() && (<>
            <button className='btnEdit'
              onClick={() => onBtnDeleteClick(item.id)}>X</button>

            <button className='btnEdit'
              onClick={() => onClickEdit(item.id)}>✏️</button>
          </>)}
        </span>
      ))}

      {data.filter((i) => i.role !== 1).map((item) => (
        <div className="containerz containerCell" key={item.id}>
          <img src={`https://encaps.click/attach/${item.fileName}`}
            align='left' alt='ico'
          />
          <b>{item.title}</b>
          {authTokenGet() && (<>
            <button className='btnEdit'
              onClick={() => onBtnDeleteClick(item.id)}>X</button>

            <button className='btnEdit'
              onClick={() => onClickEdit(item.id)}>✏️</button>
          </>)}

          <div className="textXS">
            {item.parents?.map((item, index) => (<span key={item.id + 'span'}> <a onClick={() => { fnHandler(item.id) }}>{item.title}</a> / </span>))}
          </div>

          {item.descr?.substring(0, 110)}
          {item.descr?.length > 111 ? '...' : ''}

          {loading && <AppLoading />}

        </div>
      ))}
    </div>

    <ModalDialog ref={refModal} title="Edit" handleClose={handleClose}>
      <AppModalAdd itemsTree={itemsTree} id={id} handleClose={handleClose} />
    </ModalDialog>

    {dialogConfirm && <ConfirmationDialog
      message="Proceed?"
      onConfirm={handleDialogConfirm}
      onCancel={handleDialogCancel}
    />}

  </>);


}