import './Head.css';
import React, { useState } from 'react';
import AppModal from './AppModal.js';
import AppModalAdd from './AppModalAdd.js';
import AppModalSearch from './AppModalSearch.js';
import AuthProfile from './AuthProfile.js';

export default function HeadNavi({ fnHandler, itemsTree }) {

  // const [dataFromChild, setDataFromChild] = useState(null);

  // Function to handle data received from child
  // const handleDataFromChild = (data) => {
  // setDataFromChild(data);
  // console.log("handleDataFromChild:")
  // console.log(data)
  // };

  const [propsModal, setPropsModal] = useState({
    title: null,
    isOpen: false,
    form: null
  });

  function fnHandleOpen(isOpen_) {
    setPropsModal({ ...propsModal, isOpen: isOpen_ })
  }

  const [modal, setModal] = useState(null)

  const handleClick = (title) => {
    //console.log(title)
    setModal(title);
    switch (title) {
      case "ADD":
        setPropsModal({ ...propsModal, isOpen: true, title: "Add Node" }); break;
      case "SEARCH":
        setPropsModal({ ...propsModal, isOpen: true, title: "Search" }); break;
      case "PROFILE":
        setPropsModal({ ...propsModal, isOpen: true, title: "Profile" }); break;
      default: setModal(null);
    }
  };

  return (
    <>
      <div style={{ paddingLeft: '10px' }}>
        <ul className="listNavi">
          <li><a href="#" id="idSearch" onClick={() => handleClick('SEARCH')}><img src="img/search.svg" /> Search </a></li>
        </ul>
      </div>

      <AppModal props={propsModal} fnHandleOpen={fnHandleOpen}>
        {modal && modal === "ADD" && <AppModalAdd itemsTree={itemsTree} id={0} />}
        {modal && modal === "SEARCH" && <AppModalSearch fnHandler={fnHandler} itemsTree={itemsTree} />}
      </AppModal>

    </>
  );
}