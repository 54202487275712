import HeadNavi from './HeadNavi.js'
import './Head.css';
// import { useState } from 'react';
import React, { useState, useRef } from 'react';
import ModalDialog from './ModalDialog.js';
// import AppModal from './AppModal.js';
// import AppModalAdd from './AppModalAdd.js';
// import AppModalSearch from './AppModalSearch.js';
import AuthProfile, { authTokenGet } from './AuthProfile.js';

export default function Head({ fnHandler, itemsTree }) {

  console.log("token:", authTokenGet())

  const [searchString, setSearchString] = useState("");

  function fnOnSubmit(e) {
    e.preventDefault();
    fnHandler(0, searchString);
  }

  function fnSearch(str) {
    console.log(str)
    setSearchString(str)
    //if(str.length >= 3)
    fnHandler(0, str);
  }

  const refModal = useRef()

  const handleClick = (title) => {
    console.log(title)
    refModal.current.showModal()
  };

  return (
    <>

      <ModalDialog ref={refModal} title="Profile" >
        <AuthProfile />
      </ModalDialog>

      <div className="AppHead  bgdark">

        <table width="100%" border="0">
          <tbody><tr>
            <td>🎁 <a href="/">CRUD</a></td>
            <td align="center">
              <table border="0">
                <tbody><tr>
                  <td>
                    <input type="text" placeholder="Search:"
                      name="searchString" value={searchString}
                      onChange={(e) => fnSearch(e.target.value)}
                    />
                  </td>
                  {/* <td>
                    <img src="img/search.svg" onClick={fnOnSubmit} alt='Search'/>
                  </td> */}
                </tr>
                </tbody></table>
            </td>

            <td align='right' >
              <img onClick={() => handleClick('PROFILE')}
                src={authTokenGet() ? 'img/person-fill.svg' : 'img/person.svg'}
                alt='Profile'
              />

            </td>
          </tr>
          </tbody></table>
      </div>
      {authTokenGet() ? (
        <HeadNavi fnHandler={fnHandler} itemsTree={itemsTree} />
      ) : <br />}
    </>
  );
}
